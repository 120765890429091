;(function() {
/* @ngInject */
function ModalBdayIdnumber($uibModal, $rootScope) {
    let persons;

    return function init(persons_) {
        persons = persons_;

        return {add};
    };

    function open(options = {}) {
        return $uibModal.open(angular.extend({
            templateUrl: "/app/shared/modal-bday-idnumber/modal-bday-idnumber.html",
            controller: "ModalBdayIdnumberController",
            controllerAs: "vm",
            bindToController: true,
            size: "lg",
            backdrop: "static",
            resolve: {
                persons: () => persons
            }
        }, options));
    }

    function add(scope = {}) {
        return open({scope: angular.extend($rootScope.$new(true), scope)})
    }
}

/* @ngInject */
function ModalBdayIdnumberController($uibModalInstance, $scope, persons, Helpers, $window) {
    let vm = this;

    vm.persons = [];
    vm.persons = angular.copy(persons);

    let minDate = new Date();

    vm.days = createArray(1, 31, false);
    vm.months = createArray(1, 12, false);
    vm.years = createArray(1900, minDate.getFullYear() - 18, true);

    vm.onClick = onClick;
    vm.onElementFocus = Helpers.onElementFocus;
    vm.onElementBlur = Helpers.onElementBlur;

    function createArray(start, end, reverse) {
        let array = [];
        for (var i = start; i <= end; i++) {
            array.push(i);
        }
        if (reverse)
            return array.reverse();
        else return array;
    }

    function onClick(item, elem, index) {
        if (!vm.persons[index].dateOfBirth) {
            vm.persons[index].dateOfBirth = {};
        }

        if (elem === "day") {
            vm.persons[index].dateOfBirth.day = item;
            vm.mouseoverDay[index] = false;
        } else if (elem === "month") {
            vm.persons[index].dateOfBirth.month = item;
            vm.mouseoverMonth[index] = false;
        } else if (elem === "year") {
            vm.persons[index].dateOfBirth.year = item;
            vm.mouseoverYear[index] = false;
        }
    }

    vm.close = () => $uibModalInstance.dismiss("cancel");

    vm.submit = () => {
        if ($scope.bdayIdnumber.$invalid) {
            vm.submitted = true;
        } else $uibModalInstance.close(vm.persons);
    }

}

angular.module("firmaren")
    .factory("ModalBdayIdnumber", ModalBdayIdnumber)
    .controller("ModalBdayIdnumberController", ModalBdayIdnumberController);
}());
