;(function() {
let routes = {
  index: "/",

  sro: {
    zalozenie: {
      index: "/zalozenie-firmy",
      krok1: "/zalozenie-firmy/krok1",
      krok2: "/zalozenie-firmy/krok2",
      krok3: "/zalozenie-firmy/krok3",
      krok4: "/zalozenie-firmy/krok4",
      krok5: "/zalozenie-firmy/krok5",
      objednane: "/zalozenie-firmy/objednane"
    },
    jednoosobova: {
      index: "/zalozenie-sro"
    },
    zmena: {
      index: "/zmeny-vo-firme",
      krok1: "/zmeny-vo-firme/krok1",
      krok2: "/zmeny-vo-firme/krok2",
      krok3: "/zmeny-vo-firme/krok3",
      objednane: "/zmeny-vo-firme/objednane"
    },
    zmenaRc: {
      index: "/zapis-rodneho-cisla-do-obchodneho-registra"
    },
    readymade: {
      index: "/predaj-firmy",
      krok1: "/predaj-firmy/krok1",
      krok2: "/predaj-firmy/krok2",
      krok3: "/predaj-firmy/krok3",
      objednane: "/predaj-firmy/objednane"
    },
    usszmena: {
      index: "/urob-si-sam-zmena-adresy-a-priezviska",
      krok1: "/urob-si-sam-zmeny-vo-firme/krok1",
      krok2: "/urob-si-sam-zmeny-vo-firme/krok2",
      krok3: "/urob-si-sam-zmeny-vo-firme/krok3",
      objednane: "/urob-si-sam-zmeny-vo-firme/objednane"
    },
    dokumenty: "/dokumenty-na-zalozenie-sro",
    zrusenie: "/zrusenie-a-likvidacia-sro"
  },

  zivnosti: {
    zalozenie: {
      index: "/zalozenie-zivnosti",
      krok1: "/zalozenie-zivnosti/krok1",
      krok2: "/zalozenie-zivnosti/krok2",
      krok3: "/zalozenie-zivnosti/krok3",
      objednane: "/zalozenie-zivnosti/objednane"
    },
    rozsirenie: {
      index: "/rozsirenie-zivnosti",
      krok1: "/rozsirenie-zivnosti/krok1",
      krok2: "/rozsirenie-zivnosti/krok2",
      krok3: "/rozsirenie-zivnosti/krok3",
      objednane: "/rozsirenie-zivnosti/objednane"
    },
    zrusenie: "/zrusenie-zivnosti",
    pozastavenie: "/pozastavenie-zivnosti",
    obnovenie: "/obnovenie-zivnosti",
    zmeny: {
      index: "/zmena-zivnosti",
      krok1: "/zmena-zivnosti/krok1",
      krok2: "/zmena-zivnosti/krok2",
      objednane: "/zmena-zivnosti/objednane"
    }
  },
  virtualneSidlo: {
    index: "/virtualne-sidlo",
    krok1: "/virtualne-sidlo/krok1",
    krok2: "/virtualne-sidlo/krok2",
    krok3: "/virtualne-sidlo/krok3",
    objednane: "/virtualne-sidlo/objednane"
  },
  vypisZoZivnostenskehoRegistra: {
    form: "/konverzia-dokumentov/krok1?sluzba=zivnost",
  },
  sluzby: {
    burza: {
      index: "/burza-firiem/predat/krok1",
      krok1: "/burza-firiem/predat/krok1",
      krok2: "/burza-firiem/predat/krok2",
      objednane: "/burza-firiem/predat/objednane",
      kupit: "/burza-firiem/kupit",
      detail: "/burza-firiem/detail"
    },
    sidlo: "/virtualne-sidlo",
    video: "/video-navody",
    obcianske: {
      index: "/zalozenie-obcianskeho-zdruzenia",
      krok1: "/zalozenie-obcianskeho-zdruzenia/krok1",
      krok2: "/zalozenie-obcianskeho-zdruzenia/krok2",
      krok3: "/zalozenie-obcianskeho-zdruzenia/krok3",
      objednane: "/zalozenie-obcianskeho-zdruzenia/objednane"
    },
    ussobcianske: {
      index: "/urob-si-sam-zalozenie-obcianskeho-zdruzenia",
      krok1: "/urob-si-sam-zalozenie-obcianskeho-zdruzenia/krok1",
      krok2: "/urob-si-sam-zalozenie-obcianskeho-zdruzenia/krok2",
      krok3: "/urob-si-sam-zalozenie-obcianskeho-zdruzenia/krok3",
      objednane: "/urob-si-sam-zalozenie-obcianskeho-zdruzenia/objednane"
    },
    readymadeoz: {
      index: "/hotove-obcianske-zdruzenia"
    },
    vzory: {
      index: "/vzory"
    },
    konverzia: {
      krok1: "/konverzia-dokumentov/krok1",
      krok2: "/konverzia-dokumentov/krok2",
      objednane: "/konverzia-dokumentov/objednane"
    },
    generator: "/obchodne-meno-generator"
  },

  vypisZObchodnehoRegistra: {
    form: "/konverzia-dokumentov/krok1?sluzba=spolocnost",
  },

  kuv: "/konecny-uzivatel-vyhod",
  cennik: "/cennik-sluzieb",
  uctovnici: '/uctovnici',

  onas: {
    index: "/o-nas",
    podmienky: "/o-nas/obchodne-podmienky",
    vopFirmaren: "/o-nas/vop-firmaren",
    referencie: "/o-nas/referencie",
    napisalionas: "/o-nas/napisali-o-nas",
    akofunguje: "/o-nas/ako-portal-funguje",
    faq: "/o-nas/faq",
    ochranaudajov: "/o-nas/podmienky-ochrany-osobnych-udajov"
  },

  blog: "/clanky",
  kontakt: "/kontakt",
  register: "/register",
  forgottenPassword: "/zabudnute-heslo",
  login: "/login",
  resetPw: "/reset-hesla",

  en: {
    zivnosti: {
      zalozenie: {
        index: "/en/setting-up-of-a-trade",
        krok1: "/en/setting-up-of-a-trade/step1",
        krok2: "/en/setting-up-of-a-trade/step2",
        krok3: "/en/setting-up-of-a-trade/step3",
        objednane: "/en/setting-up-of-a-trade/ordered"
      }
    }
  }
};

/* @ngInject */
function onStart($rootScope, $window, $translate) {

  let minDate = new Date();

  $rootScope.days = createArray(1,31, false);
  $rootScope.months = createArray(1,12, false);
  $rootScope.years = createArray(1900, minDate.getFullYear() - 18, true);

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  };

  let res = $window.location.pathname.split("/");
  if (res.length > 1 && res[1] === "en") {
    $translate.use('en');
  }

  $rootScope.routes = routes;
}

angular.module("firmaren")
  .constant("routes", routes)
  .run(onStart);
}());
